var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"role_admin_edit"},[_c('a-card',{attrs:{"title":_vm.delRoleItemId > -1 ? '编辑管理员' : '添加管理员',"bordered":false}},[_c('a-spin',{attrs:{"tip":"加载中...","spinning":_vm.formLoading}},[_c('a-form',{attrs:{"form":_vm.formModal,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapCol},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'role_name',
              { rules: [{ required: true, message: '请输入角色名称' }] },
            ]),expression:"[\n              'role_name',\n              { rules: [{ required: true, message: '请输入角色名称' }] },\n            ]"}],attrs:{"allowClear":"","placeholder":"请输入角色名称"}})],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'role_status',
              { initialValue: 1 },
              { rules: [{ required: true, message: '请选择状态' }] },
            ]),expression:"[\n              'role_status',\n              { initialValue: 1 },\n              { rules: [{ required: true, message: '请选择状态' }] },\n            ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v("正常")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1),_c('a-form-item',{attrs:{"label":"权限设置","required":"","validateStatus":_vm.validateStatus,"help":_vm.helpTxt,"labelCol":{ span: 24 },"wrapperCol":{ span: 24 }}},[(_vm.treeData.length > 0)?_c('div',{staticClass:"tree_area"},[_c('a-tree',{attrs:{"treeData":_vm.treeData,"checkable":"","replaceFields":_vm.replaceFields,"defaultExpandAll":""},model:{value:(_vm.roleMenuidList),callback:function ($$v) {_vm.roleMenuidList=$$v},expression:"roleMenuidList"}})],1):_vm._e()]),_c('div',{staticClass:"btns_area_modal center"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }